import { ThemePreference } from '../../types';
import { ActionType, ChangeThemeAction, ChangeShowSaveDialogAction } from './types';

export const changeTheme = (theme: ThemePreference): ChangeThemeAction => ({
  type: ActionType.changeTheme,
  theme,
});

export const changeShowSaveDialog = (showSaveDialog: boolean): ChangeShowSaveDialogAction => ({
  type: ActionType.changeShowSaveDialog,
  showSaveDialog,
});
