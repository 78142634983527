import DownloadIcon from '@mui/icons-material/Download';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Box, Container, Divider, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { pdf } from '@react-pdf/renderer';
import Markdown from 'markdown-to-jsx';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import products from '../../config/products.json';
import routes from '../../config/routes';
import { Template } from '../../exports';
import NotAvailable from '../../img/products/notavailable.jpg';
import { catalogProductsImagesSelector } from '../../state';
import { Features, Product } from '../../types';
import { TelmeButton } from '../buttons';
import { ConsultForm } from '../ConsultForm';
import IconWrapper from '../IconWrapper';
import ProductCarousel from '../ProductCarousel';
import { Section } from '../Section';
import TelmeSEO from '../TelmeSEO';
import MainFeatures from './MainFeatures';

const ProductCard = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const id = searchParams.get('id');

  const product = products.find(i => i.id === id);

  const image = useSelector(catalogProductsImagesSelector).find(i =>
    i.label.includes(`${id}.`)
  ) || {
    imgPath: NotAvailable,
    label: 'notavailable',
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClick = () => setOpen(true);

  const featuresRef = useRef<HTMLDivElement | null>(null);

  const scrollToFeatures = () => featuresRef.current?.scrollIntoView({ behavior: 'smooth' });

  const handleDownload = async () => {
    const blob = await pdf(<Template image={image.imgPath} {...(product as Product)} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${product?.salesId || id}.pdf`;
    link.click();
    URL.revokeObjectURL(url);
  };

  return id && product ? (
    <>
      <TelmeSEO
        title={product.title}
        subtitle={product.title}
        description={product.shortDescription || product.title}
        type="product"
        image={image.imgPath}
      />
      <Box mt={1}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={3}
            mb={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'minmax(0, 6fr)',
              sm: 'minmax(0, 6fr) minmax(0, 6fr)',
            }}
          >
            {!isMobile && (
              <Grid maxHeight="750px">
                <ProductCarousel images={[image]} />
              </Grid>
            )}
            <Grid mb={isMobile ? 3 : 0}>
              <Stack mt={isMobile ? 0 : 2}>
                <Box order={1} mt={isMobile ? 0 : 3}>
                  <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight={isMobile ? 700 : 600}>
                    {product.title}
                  </Typography>
                  <Typography variant={isMobile ? 'subtitle1' : 'h5'} textTransform="capitalize">
                    {product.tags}
                  </Typography>
                  <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} color="gray">
                    {product.salesId}
                  </Typography>
                </Box>
                {isMobile && (
                  <Box order={2} mt={3}>
                    <ProductCarousel images={[image]} />
                  </Box>
                )}
                <Box order={{ xs: 5, sm: 3 }} mt={3}>
                  <Typography variant="subtitle1" color="#4d545b">
                    <Markdown>{product.shortDescription as string}</Markdown>
                  </Typography>
                  <TelmeButton variant="text" onClick={scrollToFeatures} fullWidth={isMobile}>
                    {t('products.labels.seeFeatures')}
                  </TelmeButton>
                </Box>
                <Box order={{ xs: 3, sm: 4 }} mt={3}>
                  <Typography variant="subtitle1">{t('products.labels.button')}</Typography>
                  <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1}>
                    <TelmeButton onClick={handleClick} fullWidth={isMobile}>
                      {t('products.buttons.contactUs')}
                    </TelmeButton>
                    <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
                    <TelmeButton
                      variant="outlined"
                      onClick={handleDownload}
                      startIcon={<DownloadIcon fontSize="inherit" />}
                      fullWidth={isMobile}
                    >
                      {t('products.buttons.download')}
                    </TelmeButton>
                  </Box>
                </Box>
                <Box order={{ xs: 4, sm: 5 }} mt={isMobile ? 3 : 6}>
                  <IconWrapper icon={<WorkspacePremiumIcon />} variant="subtitle1" color="#4d545b">
                    {t('products.labels.warranty')}
                  </IconWrapper>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={false}>
          <Section
            ref={featuresRef}
            title={t('products.sections.features.main')}
            child={<MainFeatures features={product?.features as Features} />}
          />
          <Section
            title={t('products.sections.description')}
            child={
              <Typography
                variant="subtitle1"
                fontWeight={400}
                sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
              >
                <Markdown>{product?.description as string}</Markdown>
              </Typography>
            }
          />
        </Container>
      </Box>
      {open && <ConsultForm productId={product.salesId} handleCancel={handleClose} />}
    </>
  ) : (
    <Navigate to={routes.publicRoutes.products.path} />
  );
};

export default ProductCard;
