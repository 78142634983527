import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { useTelmeFormState } from './TelmeForm';
import { LoadingButton } from '../buttons';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface ActionsProps {
  submitLabel: ReactNode;
}

const TelmeFormActions = ({ submitLabel }: ActionsProps) => {
  const { isSubmitting, isValid } = useTelmeFormState();

  return (
    <ActionsContainer>
      <LoadingButton type="submit" loading={isSubmitting} disabled={!isValid} variant="contained">
        {submitLabel}
      </LoadingButton>
    </ActionsContainer>
  );
};

export default TelmeFormActions;
