import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { getCategories } from '../../helpers';
import {
  catalogFiltersCategoriesSelector,
  catalogProductsDataSelector,
  changeFiltersCategories,
} from '../../state';
import CategoriesList from './CategoriesList';

const handleSearchParams = (dispatch: Dispatch<AnyAction>, searchParams: URLSearchParams) => {
  const categories = searchParams.get('categories') || '';
  dispatch(changeFiltersCategories(categories));
};

const CatalogCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const productsData = useSelector(catalogProductsDataSelector);
  const categoriesFilter = useSelector(catalogFiltersCategoriesSelector);
  const categories = getCategories(productsData);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleSearchParams(dispatch, searchParams);
  }, [dispatch, searchParams]);

  return (
    !isMobile && (
      <Box>
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ wordWrap: 'break-word' }}
          textTransform="capitalize"
        >
          {categoriesFilter || t('products.title')}
        </Typography>
        <Typography variant="subtitle2" fontWeight={300}>
          {t('products.results', { num: productsData.flat().length })}
        </Typography>
        {categories && categoriesFilter === '' && (
          <CategoriesList title="categories" options={categories} mt={2} />
        )}
      </Box>
    )
  );
};

export default CatalogCategories;
