import { FunctionComponent } from 'react';
import { Products, ContactUs, History, Http404, Home, Product, PrivacyPolicy } from '../pages';

export interface RouteConfig {
  key: string;
  path: string;
  component: FunctionComponent;
  modeModal: boolean;
}

const getRouteConfig = (
  key: string,
  path: string,
  component: FunctionComponent,
  modeModal = false
): RouteConfig => ({
  key,
  path,
  component,
  modeModal,
});

const publicRoutes = {
  products: getRouteConfig('products', '/products', Products),
  product: getRouteConfig('product', '/product', Product),
  history: getRouteConfig('history', '/history', History),
  contactUs: getRouteConfig('contact-us', '/contact-us', ContactUs),
  privacyPolicy: getRouteConfig('privacy-policy', '/privacy-policy', PrivacyPolicy),
};

const homeRoute = getRouteConfig('home', '/', Home);

export default {
  publicRoutes,
  homeRoute,
  notFoundComponent: Http404,
};
