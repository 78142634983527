import styled from '@emotion/styled';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Box,
  Container,
  Divider,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { ContactInfo } from '../../components';

const FooterContainer = styled(Box)`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
`;

const Footer = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <FooterContainer pt={6} pb={1}>
      <Container maxWidth="xl">
        <Grid container mb={3} spacing={3}>
          <Grid size={{ xs: 12, sm: 11 }}>
            <ContactInfo display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={5} />
          </Grid>
          <Grid size={{ xs: 12, sm: 1 }}>
            <Typography variant="subtitle1">{t('layout.footer.followUs')}</Typography>
            <IconButton>
              <Link to="https://www.linkedin.com/company/industrias-telme-saicfi" target="_blank">
                <LinkedInIcon fontSize="large" htmlColor="white" />
              </Link>
            </IconButton>
            <IconButton>
              <Link to="https://www.instagram.com/industrias.telme/" target="_blank">
                <InstagramIcon fontSize="large" htmlColor="white" />
              </Link>
            </IconButton>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#747272' }} />
        <Box mt={1}>
          <Link to="/privacy-policy">{t('router.privacy-policy')}</Link>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
