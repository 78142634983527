import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Typography variant="body2" textTransform="capitalize">
    <Link {...props} component={RouterLink} />
  </Typography>
);

const VisualRouter = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.entries().next().value?.[1];
  const pathnames = location.pathname.split('/').filter(x => x);

  // eslint-disable-next-line no-restricted-globals
  if (searchParam && isNaN(Number(searchParam))) pathnames.push(searchParam);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      color="#b8b8b8"
    >
      <LinkRouter underline="hover" color="inherit" to="/">
        {t('router.home')}
      </LinkRouter>
      {pathnames.map((value, index) => {
        const to = `${pathnames.slice(0, index + 1).join('/')}`;
        const filteredTo = to.includes('product') ? '/products' : to;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <Typography key={to} variant="body2" sx={{ color: 'gray' }} textTransform="capitalize">
            {i18n.exists(`router.${value}`) ? t(`router.${value}`) : value}
          </Typography>
        ) : (
          <LinkRouter key={filteredTo} to={filteredTo} underline="hover" color="inherit">
            {i18n.exists(`router.${filteredTo}`) ? t(`router.${filteredTo}`) : filteredTo}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};
export default VisualRouter;
