import { Action } from 'redux';
import {
  Products,
  Filters,
  HttpErrorBaseAction,
  SendEmailForm,
  SortOption,
  TelmeImage,
} from '../../types';

export interface State {
  productsData: Products[];
  productImages: TelmeImage[];
  displayImages: TelmeImage[];
  categories: string[];
  filters: Filters;
}

export enum ActionType {
  changeFiltersSortBy = 'products/changeFiltersSortBy',
  changeFiltersSearchBy = 'products/changeFiltersSearchBy',
  changeFiltersCategories = 'products/changeFiltersCategories',
  sendEmailRequested = 'products/sendEmailRequested',
  sendEmailSuccess = 'products/sendEmailSuccess',
  sendEmailFailure = 'products/sendEmailFailure',
}

export type ChangeFiltersSortByAction = Action<ActionType.changeFiltersSortBy> & {
  sortBy: SortOption;
};

export type ChangeFiltersSearchByAction = Action<ActionType.changeFiltersSearchBy> & {
  searchBy: string;
};

export type ChangeFiltersCategoriesAction = Action<ActionType.changeFiltersCategories> & {
  categories: string;
};

export interface SendEmailRequestedAction extends Action<ActionType.sendEmailRequested> {
  payload: SendEmailForm;
}

export type SendEmailSuccessAction = Action<ActionType.sendEmailSuccess>;

export type SendEmailFailureAction = HttpErrorBaseAction<ActionType.sendEmailFailure>;

export type Actions =
  | ChangeFiltersSortByAction
  | ChangeFiltersSearchByAction
  | ChangeFiltersCategoriesAction
  | SendEmailRequestedAction
  | SendEmailSuccessAction
  | SendEmailFailureAction;
