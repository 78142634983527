import { useSelector } from 'react-redux';

import { catalogDisplayImagesSelector } from '../state';
import Carousel from './Carousel';

const Display = () => {
  const images = useSelector(catalogDisplayImagesSelector);

  return <Carousel images={images} />;
};

export default Display;
