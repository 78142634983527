/* eslint-disable import/no-unresolved */
import styled from '@emotion/styled';
import { Box, BoxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const TelmeSwiper = styled(Swiper)`
  .zoom-out {
    transform: scale(1);
    transition-delay: 0.5s;
    transform-origin: center;
    transition-duration: 0.5s;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
  .swiper-slide-active .zoom-out {
    transform: scale(1.1);
    transition-duration: 7s;
  }
`;

interface CarouselProps extends BoxProps {
  images: { label: string; imgPath: string }[];
}

const getMarketYears = () => {
  const today = new Date();
  const foundation = new Date(1956, 0, 1);
  const marketYears = today.getFullYear() - foundation.getFullYear();
  return marketYears > 68 ? marketYears : 68;
};

const Carousel = ({ images }: CarouselProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const marketYears = getMarketYears();

  return (
    <Box sx={{ height: isMobile ? '115vh' : '100vh', width: '100%' }}>
      {/* Swiper Container */}
      <TelmeSwiper
        loop
        effect="fade"
        allowTouchMove={false}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectFade]}
        style={{ maxHeight: '100%', height: '100%' }}
      >
        {images.map(i => (
          <SwiperSlide key={i.label}>
            <Box component="img" className="zoom-out" src={i.imgPath} alt={i.label} />
          </SwiperSlide>
        ))}
      </TelmeSwiper>

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: isMobile ? '115vh' : '100vh',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '85%',
            background: isMobile
              ? 'linear-gradient(180deg, rgba(17, 54, 95, 0.1) 50%, #3a4a88 80%);'
              : 'linear-gradient(180deg, rgba(17, 54, 95, 0.1) 50%, #3a4a88 100%);',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            textAlign: 'center',
          }}
        >
          <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ fontWeight: 'bold' }}>
            {t('shared.title')}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            {t('shared.slogan', { marketYears })}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '15%',
            backgroundColor: '#3a4a88',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" textAlign="center">
            {t('shared.phrase')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Carousel;
