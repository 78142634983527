import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { TypographyProps } from '@mui/material';
import { Link } from 'react-router-dom';
import IconWrapper from './IconWrapper';

const formatPhoneNumber = (phoneNumber: string, onlyEnd?: boolean) => {
  const cleaned = phoneNumber.replace(/\D/g, '');

  const countryCode = cleaned.slice(0, 2);
  const areaCode = cleaned.slice(3, 5);
  const firstPart = cleaned.slice(5, 9);
  const secondPart = cleaned.slice(9, 13);

  if (onlyEnd) return secondPart;

  return `(+${countryCode}) ${areaCode}-${firstPart}-${secondPart}`;
};

const TelmePhones = ({ color, ...rest }: TypographyProps) => {
  const { variant } = rest;
  const phoneNumbers: string[] = process.env.PHONE_NUMBERS
    ? process.env.PHONE_NUMBERS.split(',')
    : [];
  return (
    <>
      <IconWrapper icon={<PhoneAndroidOutlinedIcon />} variant={variant || 'subtitle1'} {...rest}>
        {phoneNumbers.map((i, index) => (
          <>
            <Link key={i} to={`tel:${i}`} target="_blank">
              {formatPhoneNumber(i, index !== 0)}
            </Link>
            {index !== phoneNumbers.length - 1 && '/'}
          </>
        ))}
      </IconWrapper>
      <IconWrapper icon={<WhatsAppIcon />} variant={variant || 'subtitle1'} {...rest}>
        <Link to={`https://wa.me/${process.env.WHATSAPP_NUMBER as string}`} target="_blank">
          {formatPhoneNumber(process.env.WHATSAPP_NUMBER)}
        </Link>
      </IconWrapper>
    </>
  );
};

export default TelmePhones;
