import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { ContactInfo, TelmePageContainer } from '../components';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <TelmePageContainer SEOConfig={{ title: t('router.contact-us') }}>
      <Box width="100%" mt={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
        <Grid container sx={{ backgroundColor: '#f6f6f6' }}>
          <Grid size={{ xs: 12, md: 6 }} padding={7}>
            <ContactInfo variant="subtitle2" />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }} padding={1}>
            <iframe
              title="INDUSTRIAS TELME S.A.I.C.F.I."
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13142.113687680196!2d-58.516428!3d-34.5654936!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb71b3a1ab8c7%3A0xb6366e4b5246f4a!2sINDUSTRIAS%20TELME%20S.A.!5e0!3m2!1ses-419!2sar!4v1711329846163!5m2!1ses-419!2sar"
              width="100%"
              height="100%"
              style={{ border: 0, aspectRatio: 1 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </Grid>
        </Grid>
      </Box>
    </TelmePageContainer>
  );
};

export default ContactUs;
