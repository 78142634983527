import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { OptionsObject, useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListener } from '../../hooks';
import { ProductsActionType } from '../../state';
import { SendEmailForm } from '../../types';
import { TelmeButton } from '../buttons';
import { TelmeCaptcha, TelmeForm, TextFieldFormInput } from '../TelmeForm';
import TelmeFormActions from '../TelmeForm/TelmeFormActions';
import TelmePhones from '../TelmePhones';
import Logo from '../../img/logo-invert.png';
import {
  emailMaxLength,
  emailMinLength,
  getSchema,
  messageMaxLength,
  messageMinLength,
  nameMaxLength,
  nameMinLength,
  phoneMaxLength,
  phoneMinLength,
} from './schema';

const messageInputProps = { maxLength: messageMaxLength, minLength: messageMinLength };
const nameInputProps = { maxLength: nameMaxLength, minLength: nameMinLength };
const emailInputProps = { maxLength: emailMaxLength, minLength: emailMinLength };
const phoneInputProps = { maxLength: phoneMaxLength, minLength: phoneMinLength };

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
};

interface ConsultProps {
  productId: string;
  handleCancel: () => void;
}

const Consult = ({ productId, handleCancel }: ConsultProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const padding = isMobile ? 1 : 5;

  const resolver = useMemo(() => yupResolver(getSchema(t)), [t]);
  const formListener = useListener(
    ProductsActionType.sendEmailRequested,
    ProductsActionType.sendEmailSuccess,
    ProductsActionType.sendEmailFailure
  );

  const onSubmit = async (data: SendEmailForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('products.consult.messages.success'), messageOptions);
  };

  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const [messageType, setMessageType] = useState('other');
  const [defaultMessage, setDefaultMessage] = useState(
    t(`products.consult.message.${messageType}`, { productId })
  );

  const handleChange = (event: React.MouseEvent<HTMLElement>, messageType: string) => {
    setMessageType(messageType);
    setDefaultMessage(t(`products.consult.message.${messageType}`, { productId }));
  };

  const defaultValues = useMemo(() => ({ message: defaultMessage }), [defaultMessage]);

  return (
    <TelmeForm onValidSubmit={onSubmit} resolver={resolver} defaultValues={defaultValues}>
      <Dialog
        onClose={handleCancel}
        open
        scroll="paper"
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle padding={padding}>
          <Stack flexDirection="row">
            {isMobile && (
              <Box
                component="img"
                src={Logo}
                alt="INDUSTRIAS TELME"
                sx={{
                  height: 40,
                }}
              />
            )}
            <Typography variant="h4" ml={2}>
              {t('products.consult.title')}
            </Typography>
          </Stack>
          <IconButton
            size="large"
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={5} padding={padding}>
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              {t('products.consult.labels.subtitle')}
            </Typography>
            <Box>
              <Typography variant="subtitle1" color="gray">
                {t('products.consult.labels.phoneNumbers')}
              </Typography>
              {showPhoneNumbers && (
                <Box mt={1} mb={1}>
                  <TelmePhones color="gray" />
                </Box>
              )}
              <TelmeButton variant="text" onClick={() => setShowPhoneNumbers(!showPhoneNumbers)}>
                {t('products.consult.labels.showPhoneNumbers')}
              </TelmeButton>
            </Box>

            <Stack spacing={3}>
              <Typography variant="h5">{t('products.consult.labels.messageHelp')}</Typography>
              <ToggleButtonGroup
                color="primary"
                value={messageType}
                exclusive
                aria-label="Message Example"
                onChange={handleChange}
                fullWidth
              >
                <ToggleButton value="budget">
                  {isMobile ? <RequestQuoteIcon /> : t('products.consult.labels.budget')}
                </ToggleButton>
                <ToggleButton value="info">
                  {isMobile ? <DescriptionIcon /> : t('products.consult.labels.info')}
                </ToggleButton>
                <ToggleButton value="other">
                  {isMobile ? <EditIcon /> : t('products.consult.labels.other')}
                </ToggleButton>
              </ToggleButtonGroup>
              <TextFieldFormInput
                name="message"
                type="text"
                variant="outlined"
                label={t('products.consult.labels.message')}
                required
                rows={5}
                multiline
                fullWidth
                inputProps={messageInputProps}
              />
            </Stack>

            <Grid container spacing={2}>
              <Box>
                <Typography variant="h5">{t('products.consult.labels.infoHelp')}</Typography>
                <Typography variant="subtitle1" color="gray">
                  {t('products.consult.labels.infoHelpSubtitle')}
                </Typography>
              </Box>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextFieldFormInput
                  name="name"
                  type="text"
                  variant="outlined"
                  label={t('products.consult.labels.name')}
                  required
                  fullWidth
                  inputProps={nameInputProps}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextFieldFormInput
                  name="lastname"
                  type="text"
                  variant="outlined"
                  label={t('products.consult.labels.lastname')}
                  required
                  fullWidth
                  inputProps={nameInputProps}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextFieldFormInput
                  name="email"
                  type="text"
                  variant="outlined"
                  label={t('products.consult.labels.email')}
                  required
                  fullWidth
                  inputProps={emailInputProps}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextFieldFormInput
                  name="phone"
                  type="text"
                  variant="outlined"
                  label={t('products.consult.labels.phone')}
                  fullWidth
                  inputProps={phoneInputProps}
                />
              </Grid>
            </Grid>
            <Grid size={{ xs: 12 }} display="flex" alignItems="center" justifyContent="center">
              <TelmeCaptcha name="token" />
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <TelmeFormActions submitLabel={t('products.consult.submit')} />
        </DialogActions>
      </Dialog>
    </TelmeForm>
  );
};

export default Consult;
