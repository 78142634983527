import styled from '@emotion/styled';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, IconButton, TextField } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { catalogFiltersSearchBySelector } from '../../state';
import { changeFiltersSearchBy } from '../../state/products/actionCreators';

const Container = styled.form`
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  border-radius: 2px;
`;

const SearchBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchBy = useSelector(catalogFiltersSearchBySelector);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(changeFiltersSearchBy(value));
    },
    [dispatch]
  );

  const clearSearch = () => {
    handleChange('');
  };

  return (
    <Container>
      <TextField
        value={searchBy}
        className="text"
        variant="standard"
        fullWidth
        onInput={e => {
          handleChange((e.target as HTMLTextAreaElement).value);
        }}
        placeholder={t('products.filters.search-by.label') as string | undefined}
        margin="none"
        slotProps={{
          input: {
            style: {
              paddingLeft: 15,
            },
            fullWidth: true,
            disableUnderline: true,
            endAdornment: searchBy ? (
              <IconButton onClick={clearSearch} aria-label="clear" size="small" sx={{ p: '10px' }}>
                <ClearIcon />
              </IconButton>
            ) : null,
          },
        }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton sx={{ p: '10px' }} aria-label="search" disabled>
        <SearchIcon />
      </IconButton>
    </Container>
  );
};

export default SearchBar;
