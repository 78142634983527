import { css } from '@emotion/react';

const GlobalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100..900;1,100..900&display=swap');
  @media print {
    body {
      overflow: visible !important;
    }
    .no-print {
      display: none;
    }
    .print {
      display: block !important;
    }
  }
  html,
  body {
    overflow: visible;
    font-family: 'Overpass', sans-serif;
    padding-right: 0 !important;
  }
  div#root {
    height: 100%;
  }
  a,
  a:visited {
    text-decoration: none;
    color: inherit;
  }
  .MuiPopover-paper {
    box-shadow: none !important;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    position: fixed;
    right: 0;
    z-index: 999;
  }
  ::-webkit-scrollbar-track {
    border-radius: 15px;
    height: 20px;
    max-height: 20px !important;
    line-height: 20px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    display: none;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-zoom-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    border-radius: 6px;
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }
`;

export default GlobalStyles;
