import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Collapse,
  Container,
  IconButton,
  Slide,
  Theme,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { cloneElement, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuButton, NestedList } from '../../components';
import { getMenuOptions } from '../../helpers';
import { useIsCurrentPath, useTelmeNavigate } from '../../hooks';
import Logo from '../../img/logo.png';
import { catalogCategoriesSelector, isModeModalSelector } from '../../state';

const TelmeToolbar = styled(Toolbar)`
  @media all {
    min-height: 5vw;
  }
`;

interface OnScrollProps {
  inHomePath: boolean;
  isOpen: boolean;
  showMobile: boolean;
  window?: () => Window;
  children?: ReactElement<unknown>;
}

const OnScroll = (props: OnScrollProps) => {
  const { inHomePath, isOpen, showMobile, children, window } = props;
  const [isMouseOver, setIsMouseOver] = useState(false);

  const hideTrigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const backgroundTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return children ? (
    <Slide appear={false} direction="down" in={!hideTrigger || isOpen}>
      {cloneElement(children, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onMouseEnter: !showMobile ? () => setIsMouseOver(true) : undefined,
        onMouseLeave: !showMobile ? () => setIsMouseOver(false) : undefined,
        style: {
          backgroundColor:
            !inHomePath || isMouseOver || backgroundTrigger || isOpen ? undefined : 'transparent',
          backgroundImage:
            !inHomePath || isMouseOver || backgroundTrigger || isOpen
              ? undefined
              : 'linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0) 94%)',
        },
      })}
    </Slide>
  ) : null;
};

const Header = () => {
  const navigate = useTelmeNavigate();
  const catalogCategories = useSelector(catalogCategoriesSelector);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isModeModal = useSelector(isModeModalSelector);
  const inHomePath = useIsCurrentPath('/');

  const menuOptions = getMenuOptions(navigate, catalogCategories);
  const showMobile = !isModeModal && isMobile;
  const [open, setOpen] = useState(false);
  const handleClick = (open: boolean) => () => setOpen(open);

  return (
    <OnScroll inHomePath={inHomePath} isOpen={open} showMobile={showMobile}>
      <AppBar
        position={open || inHomePath ? 'fixed' : 'sticky'}
        sx={{ maxHeight: '100%' }}
        enableColorOnDark
      >
        <Container maxWidth="xl">
          <TelmeToolbar disableGutters>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="logo"
              sx={{ mr: 2 }}
              onClick={() =>
                navigate(routes => ({
                  pathname: routes.homeRoute.path,
                }))
              }
            >
              <Box
                component="img"
                src={Logo}
                alt="INDUSTRIAS TELME"
                sx={{ height: showMobile ? 40 : 60 }}
              />
            </IconButton>

            {!showMobile && (
              <Box sx={{ flexGrow: 1, gap: 2, display: 'flex' }}>
                {menuOptions.map(i => (
                  <MenuButton key={i.label} label={i.label} options={i.options} />
                ))}
              </Box>
            )}
            <Box sx={{ flexGrow: 1, display: 'flex' }} />

            {showMobile && (
              <IconButton onClick={handleClick(!open)} color="inherit" size="large">
                {open ? <CancelIcon /> : <MenuIcon />}
              </IconButton>
            )}
          </TelmeToolbar>
        </Container>
        {showMobile && (
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ overflow: 'scroll' }}>
            <NestedList
              data={menuOptions}
              handleClose={handleClick(false)}
              sx={{ height: 'calc(100vh - 5vw)' }}
            />
          </Collapse>
        )}
      </AppBar>
    </OnScroll>
  );
};

export default Header;
