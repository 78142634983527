import { Global } from '@emotion/react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { Layout } from './layout';
import { ThemeSwitcherProvider, AppErrorAlert, ScrollToTop } from './components';
import GlobalStyles from './GlobalStyles';

// import de configs
import './config/i18n.config';
import store from './config/store/store';

const helmetContext = {};

const App = () => (
  <Provider store={store}>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ThemeSwitcherProvider>
          <SnackbarProvider maxSnack={3}>
            <AppErrorAlert />
            <CssBaseline />
            <Global styles={GlobalStyles} />
            <ScrollToTop />
            <Layout />
          </SnackbarProvider>
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);

export default App;
