import styled from '@emotion/styled';
import { Theme, useMediaQuery } from '@mui/material';
import { CustomTableData } from '../../types';

const Table = styled.table<{ isMobile: boolean }>`
  border: 1px solid #ededed;
  border-radius: 5px;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.9);
  font-size: ${props => (props.isMobile ? '14px' : '16px')};
  font-weight: 400;
  padding: 0;
  width: 100%;
  > :nth-of-type(2n + 1) {
    background: #f5f5f5;
  }

  > :nth-of-type(2n + 1) th {
    background: #ebebeb;
  }
  th {
    background: #f5f5f5;
  }
`;

const Tbody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`;

const Theader = styled.th`
  display: table-cell;
  padding: 15px 16px;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
`;

const Tcolumn = styled.td`
  padding-left: 24px;
  width: 73%;
  padding: 16px;
`;

interface CustomTableProps {
  data: CustomTableData;
}

const CustomTable = ({ data }: CustomTableProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Table isMobile={isMobile}>
      {data.map(i => (
        <Tbody key={i.header}>
          <Theader>{i.header}</Theader>
          <Tcolumn>{i.body}</Tcolumn>
        </Tbody>
      ))}
    </Table>
  );
};

export default CustomTable;
