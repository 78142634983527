import { TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTelmeForm } from './TelmeForm';

type MuiTextFieldProps = Omit<TextFieldProps, 'name'>;

export type TelmeCaptchaProps<TFieldValues extends FieldValues = FieldValues> =
  MuiTextFieldProps & {
    name: Path<TFieldValues>;
  };

const TelmeCaptcha = <TFieldValues extends FieldValues = FieldValues>({
  name,
}: TelmeCaptchaProps<TFieldValues>): JSX.Element => {
  const { control } = useTelmeForm<TFieldValues>();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field: { ref, onChange } }) => (
      <ReCAPTCHA sitekey={process.env.SITE_KEY as string} ref={ref} onChange={onChange} hl={lang} />
    ),
    [lang]
  );

  return <Controller name={name} control={control} render={render} />;
};

export default TelmeCaptcha;
