import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

interface IconWrapperProps extends TypographyProps {
  icon: ReactNode;
  children: ReactNode;
}

const IconWrapper = ({ icon, children, ...rest }: IconWrapperProps) => (
  <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }} {...rest}>
    {icon}
    {children}
  </Typography>
);

export default IconWrapper;
