import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TelmeButton } from './buttons';

interface NewProps {
  tag?: string;
  title?: string;
  description: string;
  imgPath?: string;
  action?: () => void;
  invert?: boolean;
}

const New = (props: NewProps) => {
  const { tag, title, description, imgPath, action, invert } = props;
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const rowDirection = invert ? 'row-reverse' : 'row';

  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        padding: 3,
        height: '100%',
        width: '100%',
        flexDirection: isMobile ? 'column-reverse' : rowDirection,
        backgroundColor: 'transparent',
      }}
    >
      <CardContent>
        <Stack spacing={2} mb={3}>
          <Typography
            variant="subtitle2"
            fontSize="0.875rem"
            textTransform="uppercase"
            fontWeight={800}
            color="#808080"
          >
            {tag}
          </Typography>
          <Typography variant="h4" fontSize="2.25rem">
            {title}
          </Typography>
          <Typography variant="subtitle1" fontSize="1rem">
            {description}
          </Typography>
        </Stack>
        <TelmeButton onClick={action}>{t('main.news.button')}</TelmeButton>
      </CardContent>
      <CardMedia
        component="img"
        src={imgPath}
        sx={{
          minWidth: isMobile ? '100%' : '55%',
          maxWidth: isMobile ? '100%' : '55%',
          flexShrink: 0,
          paddingLeft: invert ? 0 : 10,
          paddingRight: invert ? 10 : 0,
          padding: isMobile ? 0 : undefined,
        }}
      />
    </Card>
  );
};

export default New;
