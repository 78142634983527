/* eslint-disable react/no-unstable-nested-components */
import { Text, View, ViewProps } from '@react-pdf/renderer';
import Markdown from 'markdown-to-jsx';
import { PropsWithChildren } from 'react';

const MarkdownPDF = ({ children, ...rest }: PropsWithChildren<ViewProps>) => (
  <View {...rest}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <Markdown
      options={{
        overrides: {
          h1: { component: Text, props: { style: { fontSize: 24, fontWeight: 'bold' } } },
          p: { component: Text, props: { style: { fontSize: 12 } } },
          strong: {
            component: ({ children }) => (
              <View style={{ marginVertical: 4 }} wrap={false}>
                <Text style={{ fontWeight: 'bold' }}>
                  {'\n'}
                  {children}
                  {'\n\n'}
                </Text>
              </View>
            ),
          },
          em: { component: Text, props: { style: { fontStyle: 'italic' } } },
          li: {
            component: ({ children }) => (
              <View
                style={{ flexDirection: 'row', alignItems: 'flex-start', marginLeft: 10 }}
                wrap={false}
              >
                <Text style={{ marginRight: 5 }}>•</Text>
                <Text>{children}</Text>
              </View>
            ),
          },
          ul: { component: View, props: { style: { marginLeft: 10 } } },
          BREAK: {
            component: () => <Text style={{ marginBottom: 8 }}>{'\n\n'}</Text>,
          },
        },
      }}
    >
      {children}
    </Markdown>
  </View>
);

export default MarkdownPDF;
