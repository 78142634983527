import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TelmePageContainer } from '../components';
import { TelmeButton } from '../components/buttons';
import { useTelmeNavigate } from '../hooks';

const Http404 = () => {
  const { t } = useTranslation();
  const navigate = useTelmeNavigate();

  const handleClick = useCallback(
    () =>
      navigate(routes => ({
        pathname: routes.homeRoute.path,
      })),
    [navigate]
  );

  return (
    <TelmePageContainer>
      <Stack
        display="flex"
        alignItems="center"
        width="100%"
        mt={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
      >
        <ScreenSearchDesktopIcon htmlColor="#666" sx={{ fontSize: '200px' }} />
        <Typography variant="h6" fontWeight="bold">
          {t('http404.description')}
        </Typography>
        <TelmeButton variant="text" onClick={handleClick}>
          <Typography variant="body2">{t('http404.button')}</Typography>
        </TelmeButton>
      </Stack>
    </TelmePageContainer>
  );
};

export default Http404;
