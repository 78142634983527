import axios, { AxiosError } from 'axios';
import { HttpRequestError } from '../types';

const getNormalizedError = (axiosError: AxiosError): HttpRequestError => {
  const data = axiosError.response || { data: {}, status: -1 };
  return {
    notified: false,
    code: data.status,
    data: data.data as object | undefined,
    innerException: axiosError,
  };
};

const errorResponseHandler = (originalError: AxiosError): Promise<AxiosError> => {
  const error: HttpRequestError = getNormalizedError(originalError);
  // TODO: Agregar manejo de errores genéricos
  return Promise.reject(error);
};

const axiosFactory = (baseURL?: string, extraHeaders: Record<string, string> = {}) => {
  const axiosInstance = axios.create({ baseURL, headers: extraHeaders });

  axiosInstance.interceptors.response.use(response => response, errorResponseHandler);

  return axiosInstance;
};

export const telmeAxios = axiosFactory(process.env.TELME_API_URL);
