import { HttpRequestError } from '../../types';
import {
  ActionType,
  HttpErrorAction,
  ClearHttpErrorAction,
  ClearValidationErrorsAction,
} from './types';

export const httpError = (httpError: HttpRequestError): HttpErrorAction => ({
  type: ActionType.httpError,
  httpError,
});

export const clearHttpError = (): ClearHttpErrorAction => ({
  type: ActionType.clearHttpError,
});

export const clearValidationErrors = (): ClearValidationErrorsAction => ({
  type: ActionType.clearValidationErrors,
});
