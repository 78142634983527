import { createTheme, PaletteMode } from '@mui/material';
import { esES } from '@mui/material/locale';
import { SupportedLanguages } from './i18n/translations';

const muiLocal: Record<SupportedLanguages, object[]> = {
  es: [esES],
};

const themeFactory = (mode: PaletteMode, locale: string) => {
  const primary = { main: '#3A4A88' };
  const secondary = { main: '#DA291C' };

  const otherProps =
    mode === 'dark'
      ? {
          background: {
            paper: '#424242',
            default: '#303030',
          },
        }
      : undefined;
  const overrideComponents =
    mode === 'dark'
      ? {
          components: {
            MuiAppBar: {
              styleOverrides: {
                root: {
                  backgroundImage: 'none',
                },
              },
            },
            MuiList: {
              styleOverrides: {
                root: {
                  backgroundImage: 'none',
                },
              },
            },
            MuiDialog: {
              styleOverrides: {
                paper: {
                  backgroundImage: 'none',
                },
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  backgroundImage: 'none',
                },
              },
            },
          },
        }
      : {
          components: {
            MuiLink: {
              defaultProps: {
                underline: 'none' as const,
              },
              styleOverrides: {
                root: {
                  textDecoration: 'none',
                },
              },
            },
            MuiAppBar: {
              styleOverrides: {
                root: {
                  boxShadow: 'None',
                  transition: 'all ease-out 0.4s !important',
                },
              },
            },
            MuiDivider: {
              styleOverrides: {
                root: {
                  borderColor: '#f7f7f7',
                },
              },
            },
          },
        };

  return createTheme(
    {
      palette: {
        primary,
        secondary,
        ...otherProps,
        mode,
      },
      typography: {
        fontFamily: 'Overpass',
      },
      ...overrideComponents,
    },
    ...muiLocal[locale as SupportedLanguages]
  );
};
export default themeFactory;
