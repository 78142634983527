/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import {
  DefaultValues,
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
  useFormState,
} from 'react-hook-form';

type TelmeFormContextType<TFieldValues extends FieldValues = FieldValues, TContext = any> =
  | Pick<UseFormReturn<TFieldValues, TContext>, 'control' | 'setValue' | 'resetField' | 'reset'>
  | undefined;

const initialState: TelmeFormContextType = undefined;

const TelmeFormContext = createContext<TelmeFormContextType>(initialState);

export interface Props<TFieldValues extends FieldValues = FieldValues, TContext = any>
  extends UseFormProps<TFieldValues, TContext> {
  children: ReactNode;
  onValidSubmit: (values: TFieldValues) => void;
}

const TelmeForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
  children,
  onValidSubmit,
  defaultValues,
  ...rest
}: Props<TFieldValues, TContext>) => {
  const { handleSubmit, control, setValue, resetField, reset } = useForm<TFieldValues, TContext>({
    mode: 'all',
    defaultValues,
    ...rest,
  });

  const value = useMemo(
    () => ({ control, setValue, resetField, reset, defaultValues }),
    [control, setValue, resetField, reset, defaultValues]
  );

  useEffect(() => reset(defaultValues as DefaultValues<TFieldValues>), [defaultValues, reset]);

  return (
    <TelmeFormContext.Provider value={value as never} {...rest}>
      <form onSubmit={handleSubmit(onValidSubmit)} noValidate>
        {children}
      </form>
    </TelmeFormContext.Provider>
  );
};

export const useTelmeForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>() => {
  const context = useContext(TelmeFormContext);

  if (context === undefined)
    throw new Error('useTelmeForm debe estar dentro de TelmeFormContext.Provider');

  return context as NonNullable<TelmeFormContextType<TFieldValues, TContext>>;
};

export const useTelmeFormState = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>() => {
  const { control } = useTelmeForm<TFieldValues, TContext>();
  return useFormState<TFieldValues>({ control });
};

export default TelmeForm;
