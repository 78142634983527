import ArrowRightIcon from '@mui/icons-material/ArrowRightRounded';
import { Box, ListSubheader, styled, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List, { ListProps } from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const StyledListItemButton = styled(ListItemButton)`
  padding: 1rem 2rem !important;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.05rem;
`;

const NestedListItemButton = styled(ListItemButton)`
  display: block;
  text-transform: capitalize;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0;
  padding: 1px 0px;
  ::after {
    background-image: none;
    content: '';
    display: block;
    border-bottom: 1px solid #747272;
    width: 30%;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;

interface Item {
  label: string;
  options: {
    label: string;
    action: () => void;
  }[];
}

const ItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

interface NestedListProps extends ListProps {
  data: Item[];
  subheader?: string;
  handleClose: () => void;
}

const NestedList = (props: NestedListProps) => {
  const { data, subheader, handleClose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});

  const getBgColor = () => '#0f1929';
  const getColor = () => theme.palette.secondary.main;

  const isOpen = (path: string) => open[path] === true;

  const handleClick = (key: string) => {
    const newState = { ...open };
    newState[key] = !newState[key];
    setOpen(newState);
  };

  const handleNestedClick = (action: () => void) => {
    handleClose();
    action();
  };

  return (
    <List {...props}>
      {subheader && <ListSubheader>{subheader}</ListSubheader>}
      {data.map(i => (
        <React.Fragment key={i.label}>
          <ItemBox
            sx={{
              backgroundColor: isOpen(i.label) ? getBgColor() : undefined,
              color: isOpen(i.label) ? getColor() : undefined,
            }}
          >
            <StyledListItemButton onClick={() => handleClick(i.label)}>
              <ListItemText primary={t(i.label)} disableTypography />
              {i.options && (
                <ArrowRightIcon
                  fontSize="large"
                  sx={{
                    mr: 1,
                    transform: isOpen(i.label) ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.4s ease-out',
                  }}
                />
              )}
            </StyledListItemButton>
          </ItemBox>
          {i.options && (
            <Collapse in={open[i.label]} timeout={0} unmountOnExit>
              <List sx={{ padding: '1rem 2rem 2rem' }}>
                {i.options.map(c => (
                  <NestedListItemButton key={c.label} onClick={() => handleNestedClick(c.action)}>
                    <ListItemText primary={t(c.label)} disableTypography />
                  </NestedListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default NestedList;
