import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { catalogProductsDataSelector } from '../../../state';
import ProductBox from './ProductBox';

const ProductData = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const productsData = useSelector(catalogProductsDataSelector);

  const index = searchParams.get('page') ? (searchParams.get('page') as unknown as number) - 1 : 0;

  useEffect(() => {
    if (index >= productsData.length) {
      setSearchParams({ page: '1' });
    }
  }, [productsData, index, setSearchParams]);

  const data = productsData[index];

  return data ? (
    <div>
      {data.map(i => (
        <ProductBox key={i.id} product={i} />
      ))}
    </div>
  ) : (
    <div>{t('products.filters.search-by.no-products')}</div>
  );
};

export default ProductData;
