import { Actions, State, ActionType } from './types';

const initialState: State = {
  httpError: undefined,
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.httpError:
      return {
        ...state,
        httpError: action.httpError,
      };
    case ActionType.clearHttpError:
      return {
        ...state,
        httpError: undefined,
      };
    case ActionType.clearValidationErrors:
      return {
        ...state,
        validationErrors: [],
      };
    default:
      return state;
  }
};

export default reducer;
