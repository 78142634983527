/*
 * Es este archivo solo debe contener mappers de request/response para el consumo de las API.
 * Otros tipos de mapeos deberían ir en mappers.ts
 * Las funciones (que se exportan) deben tener el sufijo "RequestMap" o "ResponseMap" según corresponda
 */
import { SendEmailRequest } from '../services';
import { SendEmailForm } from '../types';

export const sendEmailRequestMap = (form: SendEmailForm): SendEmailRequest => ({
  token: form.token,
  message: `Nombre: ${form.name} ${form.lastname}\nEmail: ${form.email}\nNúmero: ${form.phone}\nConsulta: ${form.message}`,
});
