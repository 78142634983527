import { Resource } from 'i18next';

import es from './es.json';

export type SupportedLanguages = 'es';

const translations: Record<SupportedLanguages, Resource> = {
  es: { translation: es },
};

export default translations;
