import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { Box } from '@mui/material';
import { TelmePageContainer } from '../components';

const History = () => {
  const { t } = useTranslation();
  return (
    <TelmePageContainer SEOConfig={{ title: t('router.history') }}>
      <Box padding={5} sx={{ backgroundColor: '#f6f6f6' }}>
        <Typography variant="h5" fontWeight={700}>
          {t('history.title')}
        </Typography>
        <Divider />
        <Typography variant="subtitle1" mt={2}>
          <Markdown>{t('history.history')}</Markdown>
        </Typography>
      </Box>
    </TelmePageContainer>
  );
};

export default History;
