import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CustomTableData } from '../../types';

interface CustomListProps {
  title: string;
  data: CustomTableData;
}

const CustomList = ({ title, data }: CustomListProps) => (
  <>
    <Typography variant="h6" fontWeight={700} mt={3}>
      {title}
    </Typography>
    <Grid container spacing={2}>
      {data.map(i => (
        <Grid key={i.header} size={{ xs: 12, xl: 6 }}>
          <Typography
            component="p"
            variant="subtitle2"
            sx={{ hyphens: 'auto', wordBreak: 'break-word' }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              fontWeight={700}
              textTransform="uppercase"
            >
              {i.header}
            </Typography>
            : {i.body}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </>
);

export default CustomList;
