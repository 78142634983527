import { PreferencesState } from '../state';

const USER_PREFERENCES_KEY = 'preferences';

class AppStorage {
  static setUserPreferences(preferences: PreferencesState) {
    localStorage.setItem(USER_PREFERENCES_KEY, JSON.stringify(preferences));
  }

  static getUserPreferences(): PreferencesState | null {
    return JSON.parse(localStorage.getItem(USER_PREFERENCES_KEY) || 'null');
  }
}

export default AppStorage;
