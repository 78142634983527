import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { CatalogCategories, CatalogProducts, TelmePageContainer } from '../components';

const Products = () => {
  const { t } = useTranslation();
  return (
    <TelmePageContainer maxWidth="xl" SEOConfig={{ title: t('router.products') }}>
      <Grid container width="100%" spacing={{ sm: 0, md: 2 }}>
        <Grid size={{ sm: 0, md: 2, lg: 2, xl: 2 }}>
          <CatalogCategories />
        </Grid>
        <Grid size={{ sm: 12, md: 9, xl: 8 }} width="100%">
          <CatalogProducts />
        </Grid>
      </Grid>
    </TelmePageContainer>
  );
};

export default Products;
