import styled from '@emotion/styled';
import ArrowRightIcon from '@mui/icons-material/ArrowRightRounded';
import { Button, MenuItem, Tooltip, TooltipProps, Typography } from '@mui/material';
import { useState } from 'react';

const TelmeMenu = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} sx={{ color: 'white' }} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.palette.primary.main};
    padding: 2rem 4rem 2rem 2.5rem;
    box-sizing: border-box;
  }
`;

const TelmeMenuButton = styled(Button)`
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.05rem;
`;

const TelmeMenuItem = styled(MenuItem)`
  display: block;
  transition: all 0.4s ease-in-out;
  width: 230px;
  height: 60px;
  padding: 1 0;
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: inherit;
  }
  ::after {
    background-image: none;
    content: '';
    display: block;
    border-bottom: 1px solid #747272;
    width: 45%;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;

interface MenuButtonProps {
  label: string;
  options: {
    label: string;
    action: () => void;
  }[];
}

const MenuButton = ({ label, options }: MenuButtonProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMenuItemClick = (action: () => void) => {
    setShowTooltip(false);
    action();
  };

  return (
    <div>
      <TelmeMenu
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        placement="bottom-start"
        title={
          <>
            {options.map(item => (
              <TelmeMenuItem
                key={item.label}
                onClick={() => handleMenuItemClick(item.action)}
                disableRipple
              >
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="2rem"
                  letterSpacing={0}
                  textTransform="capitalize"
                >
                  {item.label}
                </Typography>
              </TelmeMenuItem>
            ))}
          </>
        }
      >
        <TelmeMenuButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          disableRipple
          endIcon={
            <ArrowRightIcon
              fontSize="large"
              sx={{
                transform: showTooltip ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.4s ease-out',
              }}
            />
          }
        >
          {label}
        </TelmeMenuButton>
      </TelmeMenu>
    </div>
  );
};

export default MenuButton;
