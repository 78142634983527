import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, Link } from 'react-router-dom';
import routes from '../../config/routes';
import { catalogProductsDataSelector } from '../../state';

interface CategoriesListProps extends BoxProps {
  title: string;
  options: string[];
}

const CategoriesList = (props: CategoriesListProps) => {
  const { title, options } = props;
  const { t } = useTranslation();
  const productsData = useSelector(catalogProductsDataSelector);

  return (
    <Box {...props}>
      <Typography variant="subtitle1" fontWeight={600}>
        {t(`products.categories.${title}.title`)}
      </Typography>
      <Stack spacing={1}>
        {options.map(i => (
          <Link
            key={i}
            to={`${routes.publicRoutes.products.path}?${createSearchParams({ categories: i })}`}
          >
            <Typography
              key={i}
              variant="subtitle2"
              textTransform="capitalize"
              color="rgb(168, 160, 149)"
              fontWeight={300}
            >
              {i} ({productsData.flat().filter(p => p.tags?.includes(i)).length})
            </Typography>
          </Link>
        ))}
      </Stack>
    </Box>
  );
};

export default CategoriesList;
