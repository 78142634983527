import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { useTelmeNavigate } from '../../hooks';
import Prods from '../../img/demo.png';
import Historia from '../../img/historia2.jpeg';
import New from '../New';

const StyledStack = styled(Stack)`
  & > *:nth-of-type(odd) {
    background-color: #ededed;
  }
`;

interface NewContainerProps {
  children: ReactNode;
}

const NewContainer = ({ children }: NewContainerProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      minHeight={isMobile ? '100vh' : '50vh'}
      maxHeight="100%"
      maxWidth="100%"
    >
      <Grid item xs sm md={12} lg={8} alignItems="center" justifyContent="center">
        {children}
      </Grid>
    </Grid>
  );
};

const NewsDisplay = () => {
  const { t } = useTranslation();
  const navigate = useTelmeNavigate();

  return (
    <StyledStack alignItems="center" justifyContent="center">
      <NewContainer>
        <New
          tag={t('main.news.new1.tag')}
          title={t('main.news.new1.title')}
          description={t('main.news.new1.description')}
          imgPath={Historia}
          action={() =>
            navigate(routes => ({
              pathname: routes.publicRoutes.history.path,
            }))
          }
          invert
        />
      </NewContainer>
      <NewContainer>
        <New
          tag={t('main.news.new2.tag')}
          title={t('main.news.new2.title')}
          description={t('main.news.new2.description')}
          imgPath={Prods}
          action={() =>
            navigate(routes => ({
              pathname: routes.publicRoutes.products.path,
            }))
          }
        />
      </NewContainer>
    </StyledStack>
  );
};

export default NewsDisplay;
