import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { createSearchParams, Link } from 'react-router-dom';
import routes from '../../../config/routes';
import NotAvailable from '../../../img/products/notavailable.jpg';
import { catalogProductsImagesSelector } from '../../../state';
import { Product, TelmeImage } from '../../../types';

const Title = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-transform: uppercase;
  overflow: hidden;
`;

const Description = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

interface ProductBoxProps {
  product: Product;
}

const ProductBox = ({ product }: ProductBoxProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const image: TelmeImage = useSelector(catalogProductsImagesSelector).find(i =>
    i.label.includes(`${product.id}.`)
  ) || { imgPath: NotAvailable, label: 'notavailable' };

  return (
    <Link to={`${routes.publicRoutes.product.path}?${createSearchParams({ id: product.id })}`}>
      <Card
        sx={{
          display: 'flex',
          minHeight: isMobile ? '170px' : '210px',
          maxHeight: isMobile ? '170px' : '210px',
          flexDirection: 'row',
          borderRadius: 0,
          borderBottom: 'thin solid #eee',
        }}
      >
        <CardActionArea>
          <Box display="flex" flexDirection="row" padding={3} width="100%" height="100%" gap={2}>
            <CardMedia
              component="img"
              loading="lazy"
              src={image.imgPath}
              alt={image.label}
              sx={{
                objectPosition: 'center',
                objectFit: 'contain',
                maxWidth: isMobile ? '30%' : '20%',
                flexShrink: 0,
                borderRadius: '6px',
              }}
            />
            <CardContent sx={{ padding: 0 }}>
              <Title variant={isMobile ? 'subtitle1' : 'h6'} fontWeight={300}>
                {product.title}
              </Title>
              <Description variant={isMobile ? 'subtitle2' : 'subtitle1'} color="#666">
                {product.shortDescription}
              </Description>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default ProductBox;
