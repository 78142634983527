/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSearchParams } from 'react-router-dom';
import { PathResolver } from '../hooks';
import { Products, GroupBy, Product, SortOption } from '../types';

export const groupBy = <TValue>(
  options: readonly TValue[],
  propGetter: (group: TValue) => string
): GroupBy<TValue> =>
  options.reduce((prev, curr) => {
    const group = propGetter(curr);
    if (!(group in prev)) prev[group] = [];
    prev[group].push(curr);
    return prev;
  }, {} as GroupBy<TValue>);

export const onlyUnique = <T>(data: T[]): T[] => [...new Set(data)];

export const formatAmount = (locale: Locale, maximumFractionDigits: number, value: number) =>
  Intl.NumberFormat(locale.code, { maximumFractionDigits }).format(value);

export const importAll = (r: { (arg0: string): string; (arg0: string): string; keys: any }) => {
  const images: { [key: string]: any } = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  r.keys().forEach((item: string, _index: number) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};

export const importImages = (path: any) =>
  Object.entries(importAll(path)).map(([key, value]) => ({ imgPath: value.default, label: key }));

export function batchReduce<T>(arr: T[], batchSize: number): T[][] {
  return arr.reduce((batches, curr, i) => {
    if (i % batchSize === 0) batches.push([]);
    batches[batches.length - 1].push(arr[i]);
    return batches;
  }, [] as T[][]);
}

export const searchParse = (str: string) =>
  str
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const sortOptions: SortOption[] = [
  {
    description: 'title',
    sort: (a: Product, b: Product) => (a.title < b.title ? -1 : 1),
  },
  // {
  //   description: 'priceDesc',
  //   sort: (a: Product, b: Product) => (a.price > b.price ? -1 : 1),
  // },
  // {
  //   description: 'priceAsc',
  //   sort: (a: Product, b: Product) => (a.price < b.price ? -1 : 1),
  // },
];

export const getCategories = (productsData: Products[]) => {
  const categories: string[][] = [];
  productsData?.flat().forEach(i => {
    if (i.tags) categories.push(i.tags.split(','));
  });
  return onlyUnique(categories.flat());
};

export const navigateToExternalLink = (url: string, newWindow = true) => {
  window.open(url, newWindow ? '_blank' : undefined, 'noopener,noreferrer');
};

export const getMenuOptions = (
  navigate: (pathResolver: PathResolver) => void,
  catalogCategories: string[]
) => {
  const categoriesOptions = catalogCategories.map(i => ({
    label: i,
    action: () =>
      navigate(routes => ({
        pathname: routes.publicRoutes.products.path,
        search: `?${createSearchParams({ categories: i })}`,
      })),
  }));

  const menuOptions = [
    {
      label: 'Empresa',
      options: [
        {
          label: 'Nuestra Historia',
          action: () =>
            navigate(routes => ({
              pathname: routes.publicRoutes.history.path,
            })),
        },
        {
          label: 'Contacto',
          action: () =>
            navigate(routes => ({
              pathname: routes.publicRoutes.contactUs.path,
            })),
        },
      ],
    },
    {
      label: 'Productos',
      options: [
        {
          label: 'Todos',
          action: () =>
            navigate(routes => ({
              pathname: routes.publicRoutes.products.path,
            })),
        },
        ...categoriesOptions,
      ],
    },
  ];

  return menuOptions;
};
