import { telmeAxios } from '../config/axiosInstances';

export interface SendEmailRequest {
  token: string;
  message: string;
}

export default class {
  static async sendEmail(data: SendEmailRequest) {
    return telmeAxios.post(`/send_email`, data);
  }
}
