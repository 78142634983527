import styled from '@emotion/styled';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
import routes from '../../config/routes';
import { catalogProductsDataSelector } from '../../state';
import { ProductData } from './ProductData';
import SearchBar from './SearchBar';

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const CatalogProducts = () => {
  const [searchParams] = useSearchParams();
  const productsData = useSelector(catalogProductsDataSelector);

  const otherParams = Object.fromEntries([...searchParams]);
  const page = parseInt(searchParams.get('page') || '1', 10);

  const getParams = (page: number | null) => ({
    ...otherParams,
    ...(page && { page: page.toString() }),
  });

  return (
    <Container container gap={2}>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <SearchBar />
        <ProductData />
        <Pagination
          page={page}
          count={productsData.length}
          renderItem={item => (
            <PaginationItem
              component={Link}
              to={`${routes.publicRoutes.products.path}${`?${createSearchParams(
                getParams(item.page)
              )}`}`}
              {...item}
            />
          )}
        />
      </Stack>
    </Container>
  );
};
export default CatalogProducts;
