/* eslint-disable import/no-unresolved */
import styled from '@emotion/styled';
import { Box, BoxProps, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import { Navigation, Pagination, Thumbs, Zoom } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

const TelmeSwiper = styled(Swiper)`
  height: 80%;
  width: 100%;
  border-radius: 6px;
  .swiper-wrapper {
    aspect-ratio: 1;
  }
  .swiper-slide {
    background: #f7f7f7;
  }
`;

const TelmeThumbs = styled(Swiper)`
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
  .swiper-slide {
    background: #f7f7f7;
    border-radius: 6px;
    border: 2px solid transparent;
  }
  .swiper-slide:hover {
    border: 2px solid #b7b7b7;
    cursor: pointer;
  }
  .swiper-slide-thumb-active {
    border: 2px solid #0074e0 !important;
  }
`;

interface CarouselProps extends BoxProps {
  images: { label: string; imgPath: string }[];
}

const ProductCarousel = ({ images }: CarouselProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  return (
    <>
      <TelmeSwiper
        zoom={{
          toggle: isMobile,
        }}
        pagination={isMobile}
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Zoom, Navigation, Thumbs, Pagination]}
        onSwiper={setSwiperInstance}
      >
        {images.map(i => (
          <SwiperSlide key={i.label}>
            <Box
              className="swiper-zoom-container"
              onMouseEnter={isMobile ? undefined : () => swiperInstance?.zoom?.toggle()}
              onMouseLeave={isMobile ? undefined : () => swiperInstance?.zoom?.toggle()}
            >
              <Box component="img" src={i.imgPath} alt={i.label} />
            </Box>
          </SwiperSlide>
        ))}
      </TelmeSwiper>

      {!isMobile && (
        <TelmeThumbs
          navigation
          spaceBetween={10}
          slidesPerView={5}
          modules={[Navigation, Thumbs]}
          onSwiper={setThumbsSwiper}
        >
          {images.map(i => (
            <SwiperSlide key={i.label}>
              <Box component="img" src={i.imgPath} alt={i.label} />
            </SwiperSlide>
          ))}
        </TelmeThumbs>
      )}
    </>
  );
};

export default ProductCarousel;
