import { call, put, takeLatest } from 'redux-saga/effects';

import { sendEmailRequestMap } from '../../helpers/messagesMappers';
import { TelmeApi } from '../../services';
import { HttpRequestError } from '../../types';
import { sendEmailFailure, sendEmailSuccess } from './actionCreators';
import { ActionType, SendEmailRequestedAction } from './types';

function* sendEmail({ payload }: SendEmailRequestedAction) {
  try {
    yield call(TelmeApi.sendEmail, sendEmailRequestMap(payload));
    yield put(sendEmailSuccess());
  } catch (error) {
    yield put(sendEmailFailure(error as HttpRequestError));
  }
}

export default [takeLatest(ActionType.sendEmailRequested, sendEmail)];
