import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Display, NewsDisplay, TelmeSEO } from '../components';

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <TelmeSEO title={t('brandName')} />
      <Stack>
        <Display />
        <NewsDisplay />
      </Stack>
    </>
  );
};

export default Home;
