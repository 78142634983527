import { Container, ContainerProps, Divider, Stack, Theme, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import TelmeSEO, { TelmeSEOProps } from './TelmeSEO';
import VisualRouter from './VisualRouter';

interface TelmePageContainerProps extends ContainerProps {
  children: ReactNode;
  SEOConfig?: TelmeSEOProps;
}

const TelmePageContainer = ({ children, SEOConfig, ...rest }: TelmePageContainerProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <>
      {SEOConfig && <TelmeSEO {...SEOConfig} />}
      <Stack alignItems="center" spacing={1} mb={5} flexGrow={1}>
        <Divider flexItem />
        <VisualRouter />
        <Divider flexItem />
        <Container disableGutters={isMobile} sx={{ display: 'flex', minHeight: '100vh' }} {...rest}>
          {children}
        </Container>
      </Stack>
    </>
  );
};

export default TelmePageContainer;
