import appConfig from '../../config/app-config.json';
import products from '../../config/products.json';
import { batchReduce, getCategories, importImages, searchParse } from '../../helpers/utls';
import { Product, Products } from '../../types';
import { ActionType, Actions, State } from './types';

const searchByFilter = (products: Products, searchBy: string) =>
  products.filter(
    i =>
      searchParse(i.title).includes(searchParse(searchBy)) ||
      searchParse(i.salesId).includes(searchParse(searchBy))
  );

const categoriesFilter = (products: Products, categories: string) =>
  categories !== '' ? products.filter(i => i.tags?.includes(categories)) : products;

const applyFilters = (products: Products, searchBy: string, categories: string) =>
  categoriesFilter(searchByFilter(products, searchBy), categories);

const initialState: State = {
  productsData: batchReduce(
    products.sort((a, b) => (a.title < b.title ? -1 : 1)),
    appConfig.products.productsPerPage
  ),
  productImages: importImages(require.context('../../img/products', false, /\.(png|jpe?g|svg)$/)),
  displayImages: importImages(require.context('../../img/display', false, /\.(png|jpe?g|svg)$/)),
  categories: getCategories(batchReduce(products, appConfig.products.productsPerPage)),
  filters: {
    sortBy: {
      description: 'title',
      sort: (a: Product, b: Product) => (a.title < b.title ? -1 : 1),
    },
    searchBy: '',
    categories: '',
  },
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.changeFiltersSortBy:
      return {
        ...state,
        productsData: batchReduce(
          products.sort(action.sortBy.sort),
          appConfig.products.productsPerPage
        ),
        filters: {
          ...state.filters,
          sortBy: action.sortBy,
        },
      };
    case ActionType.changeFiltersSearchBy:
      return {
        ...state,
        productsData: batchReduce(
          applyFilters(products, action.searchBy, state.filters.categories),
          appConfig.products.productsPerPage
        ),
        filters: {
          ...state.filters,
          searchBy: action.searchBy,
        },
      };
    case ActionType.changeFiltersCategories:
      return {
        ...state,
        productsData: batchReduce(
          applyFilters(products, state.filters.searchBy, action.categories),
          appConfig.products.productsPerPage
        ),
        filters: {
          ...state.filters,
          searchBy: state.filters.searchBy,
          categories: action.categories,
        },
      };
    default:
      return state;
  }
};

export default reducer;
