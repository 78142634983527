import styled from '@emotion/styled';
import { useEffect } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import routes from '../../config/routes';
import { navigateToExternalLink } from '../../helpers';
import { isModeModalSelector, modeModal } from '../../state';
import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.main`
  flex-grow: 1;
  overflow-x: hidden;
`;

const routesModeModal = Object.values(routes.publicRoutes)
  .filter(i => i.modeModal)
  .map(i => i.path);

const ModeModalHandle = () => {
  const isModeModal = useSelector(isModeModalSelector);
  const location = useLocation();
  const shouldShowModeModal = routesModeModal.includes(location.pathname);
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldShowModeModal !== isModeModal) dispatch(modeModal(shouldShowModeModal));
  }, [shouldShowModeModal, isModeModal, dispatch]);
  return null;
};

const PublicLayout = () => (
  <Container>
    <FloatingWhatsApp
      className="no-print"
      phoneNumber=""
      accountName=""
      onClick={() => {
        navigateToExternalLink(`https://wa.me/${process.env.WHATSAPP_NUMBER as string}`);
      }}
      chatboxHeight={0}
      notification={false}
    />
    <ModeModalHandle />
    <Header />
    <Main>
      <Outlet />
    </Main>
    <Footer />
  </Container>
);

export default PublicLayout;
