import { TextField, TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { useTelmeForm } from './TelmeForm';

type MuiTextFieldProps = Omit<TextFieldProps, 'name'>;

export type TextFieldFormInputProps<TFieldValues extends FieldValues = FieldValues> =
  MuiTextFieldProps & {
    name: Path<TFieldValues>;
  };

const TextFieldFormInput = <TFieldValues extends FieldValues = FieldValues>({
  name,
  ...rest
}: TextFieldFormInputProps<TFieldValues>): JSX.Element => {
  const { control } = useTelmeForm<TFieldValues>();

  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field: { ref, ...restField }, fieldState: { error } }) => (
      <TextField
        variant="standard"
        {...restField}
        {...rest}
        name={name}
        inputRef={ref}
        error={Boolean(error)}
        helperText={error?.message}
      />
    ),
    [name, rest]
  );

  return <Controller name={name} control={control} render={render} />;
};

export default TextFieldFormInput;
