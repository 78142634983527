import * as yup from 'yup';
import { SendEmailForm, Translation } from '../../types';

export const nameMinLength = 2;
export const nameMaxLength = 50;

export const emailMinLength = 5;
export const emailMaxLength = 254;

export const phoneMinLength = 7;
export const phoneMaxLength = 15;

export const messageMinLength = 10;
export const messageMaxLength = 500;

const phoneNumberRegex =
  /^\+?(\d{1,3})?[-. ]?(\(?\d{1,4}?\)?[-. ]?)?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;

export const getSchema = (t: Translation): yup.ObjectSchema<SendEmailForm> =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .min(nameMinLength, t('validations.minLength', { length: nameMinLength }))
      .max(nameMaxLength, t('validations.maxLength', { length: nameMaxLength })),
    lastname: yup
      .string()
      .required()
      .min(nameMinLength, t('validations.minLength', { length: nameMinLength }))
      .max(nameMaxLength, t('validations.maxLength', { length: nameMaxLength })),
    email: yup
      .string()
      .email()
      .required()
      .min(emailMinLength, t('validations.minLength', { length: emailMinLength }))
      .max(emailMaxLength, t('validations.maxLength', { length: emailMaxLength })),
    phone: yup.string().when({
      is: (value: string) => value,
      then: () =>
        yup
          .string()
          .matches(phoneNumberRegex, t('validations.invalidPhoneNumber'))
          .min(phoneMinLength, t('validations.minLength', { length: phoneMinLength }))
          .max(phoneMaxLength, t('validations.maxLength', { length: phoneMaxLength })),
    }),
    message: yup
      .string()
      .required()
      .min(messageMinLength, t('validations.minLength', { length: messageMinLength }))
      .max(messageMaxLength, t('validations.maxLength', { length: messageMaxLength })),
    token: yup.string().required(),
  });
