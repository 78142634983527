import { combineReducers, ReducersMapObject } from 'redux';
import {
  AppState,
  preferencesReducer,
  layoutReducer,
  errorReducer,
  productsReducer,
} from '../../state';

const rootReducers: ReducersMapObject<AppState, never> = {
  preferences: preferencesReducer,
  layout: layoutReducer,
  error: errorReducer,
  products: productsReducer,
};

export default combineReducers(rootReducers);
