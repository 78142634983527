import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconWrapper from './IconWrapper';
import TelmePhones from './TelmePhones';

interface ContactInfoProps extends BoxProps {
  variant?: Variant;
}

const ContactInfo = ({ variant, ...rest }: ContactInfoProps) => {
  const { t } = useTranslation();
  const email: string = process.env.EMAIL || '';
  return (
    <Box display="flex" flexDirection="column" gap={4} {...rest}>
      <Box>
        <Typography variant="h6" fontWeight={800}>
          {t('title')}
        </Typography>
        <Stack mt={1} spacing={1}>
          <Typography variant={variant || 'subtitle2'}>
            Calle 48 (ex Estrada) N° 1327 (1650)
          </Typography>
          <Typography variant={variant || 'subtitle2'}>Villa Maipú, San Martín</Typography>
          <Typography variant={variant || 'subtitle2'}>Provincia de Buenos Aires</Typography>
          <Typography variant={variant || 'subtitle2'}>Argentina</Typography>
        </Stack>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={800}>
          {t('contactUs.customerService')}
        </Typography>
        <Stack mt={1} spacing={1}>
          <IconWrapper icon={<EmailOutlinedIcon />} variant={variant || 'subtitle2'} mt={1}>
            <Link to={`mailto:${email}`} target="_blank">
              {email}
            </Link>
          </IconWrapper>
          <TelmePhones variant={variant || 'subtitle2'} />
          <IconWrapper icon={<GroupsOutlinedIcon />} variant={variant || 'subtitle2'}>
            Lunes a Viernes de 9:00 a 12:00hs y de 13:00 a 18:00.
          </IconWrapper>
        </Stack>
      </Box>
    </Box>
  );
};

export default ContactInfo;
