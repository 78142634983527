import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { CustomTableData, Features } from '../../types';
import { CustomList } from '../CustomList';
import { CustomTable } from '../CustomTable';

interface MainFeaturesProps {
  features: Features;
}

const MainFeatures = ({ features }: MainFeaturesProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <CustomTable data={features.main as CustomTableData} />
      {features.other && (
        <CustomList
          title={t('products.sections.features.other')}
          data={features.other as CustomTableData}
        />
      )}
    </Stack>
  );
};

export default MainFeatures;
