import { Action } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { HttpErrorBaseAction } from '../../types';
import { httpError } from './actionCreators';

function* handleRequestErrors(actions: HttpErrorBaseAction<unknown>) {
  yield put(httpError(actions.error));
}

export default [takeLatest((action: Action) => /Failure$/i.test(action.type), handleRequestErrors)];
