import { HttpRequestError, SortOption } from '../../types';
import {
  ActionType,
  ChangeFiltersCategoriesAction,
  ChangeFiltersSearchByAction,
  ChangeFiltersSortByAction,
  SendEmailFailureAction,
  SendEmailSuccessAction,
} from './types';

export const changeFiltersSortBy = (sortBy: SortOption): ChangeFiltersSortByAction => ({
  type: ActionType.changeFiltersSortBy,
  sortBy,
});

export const changeFiltersSearchBy = (searchBy: string): ChangeFiltersSearchByAction => ({
  type: ActionType.changeFiltersSearchBy,
  searchBy,
});

export const changeFiltersCategories = (categories: string): ChangeFiltersCategoriesAction => ({
  type: ActionType.changeFiltersCategories,
  categories,
});

export const sendEmailSuccess = (): SendEmailSuccessAction => ({
  type: ActionType.sendEmailSuccess,
});

export const sendEmailFailure = (error: HttpRequestError): SendEmailFailureAction => ({
  type: ActionType.sendEmailFailure,
  error,
});
