import { Box, Container, ContainerProps, Divider, Stack, Typography } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

interface SectionProps extends ContainerProps {
  title: string;
  child: JSX.Element;
}

const Section = forwardRef(
  ({ title, child, ...rest }: SectionProps, ref: ForwardedRef<HTMLDivElement>) => (
    <Stack width="100%" ref={ref} mb={3}>
      <Box>
        <Divider />
        <Container {...rest}>
          <Typography variant="h5" fontWeight={700} mt={3}>
            {title}
          </Typography>
          <Box mt={2}>{child}</Box>
        </Container>
      </Box>
    </Stack>
  )
);

export default Section;
