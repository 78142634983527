import { Divider, Box, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import { TelmePageContainer } from '../components';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <TelmePageContainer SEOConfig={{ title: t('router.privacy-policy') }}>
      <Box padding={5} sx={{ backgroundColor: '#f6f6f6' }}>
        <Typography variant="h5" fontWeight={700}>
          {t('privacyPolicy.title')}
        </Typography>
        <Divider />
        <Typography variant="subtitle1" mt={2}>
          <Markdown>{t('privacyPolicy.policy')}</Markdown>
        </Typography>
      </Box>
    </TelmePageContainer>
  );
};

export default PrivacyPolicy;
