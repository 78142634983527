import { Route, Routes } from 'react-router-dom';
import PublicLayout from './PublicLayout/Layout';
import routes from '../config/routes';
import PublicRoute from './PublicRoute/PublicRoute';

const publicRoutes = Object.values(routes.publicRoutes);

const Layout = () => (
  <Routes>
    <Route element={<PublicLayout />}>
      <Route
        path={routes.homeRoute.path}
        element={<PublicRoute component={routes.homeRoute.component} />}
      />
      {publicRoutes.map(i => (
        <Route key={i.key} path={i.path} element={<PublicRoute component={i.component} />} />
      ))}
      <Route path="*" element={<PublicRoute component={routes.notFoundComponent} />} />
    </Route>
  </Routes>
);

export default Layout;
