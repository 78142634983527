import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Logo from '../img/logo.png';

export interface TelmeSEOProps {
  title: string;
  subtitle?: string;
  description?: string;
  type?: string;
  card?: string;
  image?: string;
}

const appendBrandName = (text: string, brandName: string) =>
  text.includes(brandName) ? text : `${text} | ${brandName}`;

const TelmeSEO = ({ title, description, type, subtitle, card, image }: TelmeSEOProps) => {
  const { t } = useTranslation();
  const brandName = t('brandName');
  const url = 'https://telme.com.ar';
  const metaDescription = description || t('shared.phrase');

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{appendBrandName(title, brandName)}</title>
      <meta name="description" content={metaDescription} />
      {/* Facebook tags */}
      <meta property="og:type" content={type || 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={brandName} />
      <meta property="og:image:alt" content={brandName} />
      <meta name="og:image" content={image || Logo} />
      {/* Twitter tags */}
      {/* <meta name="twitter:creator" content={name} /> */}
      <meta name="twitter:card" content={card || 'summary_large_image'} />
      <meta name="twitter:site" content={url} />
      <meta name="twitter:title" content={appendBrandName(subtitle || title, brandName)} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image || Logo} />
    </Helmet>
  );
};

export default TelmeSEO;
