import { AppState } from './AppState';

export const preferencesSelector = (state: AppState) => state.preferences;
export const themeSelector = (state: AppState) => state.preferences.theme;

export const isModeModalSelector = (state: AppState) => state.layout.isModeModal;
export const isSidebarOpenSelector = (state: AppState) => state.layout.isSidebarOpen;

export const erroHttpErrorSelector = (state: AppState) => state.error.httpError;

export const catalogProductsDataSelector = (state: AppState) => state.products.productsData;
export const catalogProductsImagesSelector = (state: AppState) => state.products.productImages;
export const catalogDisplayImagesSelector = (state: AppState) => state.products.displayImages;
export const catalogCategoriesSelector = (state: AppState) => state.products.categories;
export const catalogFiltersSearchBySelector = (state: AppState) => state.products.filters.searchBy;
export const catalogFiltersSortBySelector = (state: AppState) => state.products.filters.sortBy;
export const catalogFiltersCategoriesSelector = (state: AppState) =>
  state.products.filters.categories;
