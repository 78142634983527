import { To, useNavigate } from 'react-router-dom';
import routes from '../config/routes';

export type PathResolver = (pathResolver: typeof routes) => To;

const useTelmeNavigate = () => {
  const navigate = useNavigate();
  return (pathResolver: PathResolver) => navigate(pathResolver(routes));
};

export default useTelmeNavigate;
